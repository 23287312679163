import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import ComingSoon from "../ComingSoon/ComingSoon";

function App() {
	return (
		<Router>
			<main id="main">
				<Routes>
					<Route path="/" element={<ComingSoon />} />
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</main>
		</Router>
	);
}

export default App;

// Code for csrf validation - Needed for forms
// const API_HOST = 'http://localhost:8000';

// let _csrfToken = null;

// async function getCsrfToken() {
//     if (_csrfToken === null) {
//         const response = await fetch(`${API_HOST}/csrf/`, {
//             credentials: 'include',
//         });
//         const data = await response.json();
//         _csrfToken = data.csrfToken;
//     }
//     return _csrfToken;
// }

// async function testRequest(method) {
//     const response = await fetch(`${API_HOST}/ping/`, {
//         method: method,
//         headers: (
//             method === 'POST'
//             ? {'X-CSRFToken': await getCsrfToken()}
//             : {}
//         ),
//         credentials: 'include',
//     });
//     const data = await response.json();
//     return data.result;
// }
// const [testGet, setTestGet] = useState("KO");
// const [testPost, setTestPost] = useState("KO");

// useEffect(() => {
//     const setState = async () => {
//         setTestGet(await testRequest("GET"));
//         setTestPost(await testRequest("POST"));
//     }
//     setState()
// });
