// Imports
import React from "react";
import "./ComingSoon.css";
// Component Imports
// Pages Imports
// Other Imports

export default function ComingSoon() {
	return (
		<>
			<title>Coming Soon...</title>
			<div className="neon-sign-container">
				<h1 className="neon-sign">Under Construction</h1>
			</div>
		</>
	);
}
